<template>
  <config-content title="Choose a shape">
    <config-grid>
      <config-item
        v-for="shape in shapes"
        :key="shape.id"
        :name="shape.name"
        :image="shape.thumb"
        :selected="shape.id == selectedShape"
        group="shape"
        @select="handleSelect"
      />
    </config-grid>
  </config-content>
</template>

<script>
import shapes from "../data/shapes.json";
import ConfigContent from "./ConfigContent";
import ConfigGrid from "./ConfigGrid";
import ConfigItem from "./ConfigItem";

export default {
  components: {
    ConfigContent,
    ConfigGrid,
    ConfigItem,
  },

  data() {
    return {
      shapes: shapes,
    };
  },

  computed: {
    selectedShape() {
      return this.$store.state.shape;
    },
  },

  methods: {
    handleSelect(id) {
      this.$store.commit("setShape", id);
    },
  },
};
</script>
