<template>
  <canvas
    :width="width"
    :height="height"
    ref="canvas"
  />
</template>

<script>
import PreviewMixin from "../mixins/PreviewMixin";
import PanelWall from "../lib/PanelWall";

export default {
  mixins: [PreviewMixin],

  data() {
    return {
      width: 2880,
      height: 1728,
      backgroundSrc: require("../assets/rooms/wall/background.jpg"),
      foregroundSrc: require("../assets/rooms/wall/foreground.png"),
    };
  },

  methods: {
    draw() {
      this.$emit("update:loading", true);

      let x = 856;
      let y = 226;
      let spacing = this.panel.panelWidth;

      this.drawBefore()
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x, y))
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x+spacing, y))
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x+spacing*2, y))
        .then(() => this.drawAfter())
        .then(() => this.$emit("update:loading", false));
    },
  },

  mounted() {
    this.panel = new PanelWall(8.13);
    this.draw();
  },
};
</script>
