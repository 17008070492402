<template>
  <header class="flex items-center p-2 shadow z-50 md:px-4" style="background: #595959F2;">
    <a href="https://www.takeform.net">
      <img class="h-8 md:h-10 p-1 md:p-1.5" src="../assets/logo.svg" alt="Takeform Logo" />
    </a>
    <h1 class="ml-auto text-right leading-4">
      <span class="text-brand-green font-medium md:text-2xl">
        Oomph
      </span>
      <div class="md:hidden" />
      <span class="text-gray-300 text-xs md:ml-2 md:text-lg">
        Pattern Visualizer
      </span>
    </h1>
  </header>
</template>
