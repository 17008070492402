import store from "../store";
import Panel from "./Panel.js";

export default class PanelShape extends Panel {
  constructor(scale, width, height) {
    super();

    this.type = "shape";
    this.scale = scale;
    this.panelWidth = width;
    this.panelHeight = height;

    this.canvasWidth = width;
    this.canvasHeight = height;

    this.panelPath = this.getPanelPath();
  }

  afterDraw(ctx) {
    return new Promise((resolve) => {
      const shape = store.getters.shape;

      if (!shape) {
        return resolve();
      }

      this.loadImage("shape_chamfer", { src: shape.chamfer }).then((data) => {
        ctx.globalAlpha = 0.40;
        ctx.drawImage(data.img, 0, 0, this.canvasWidth, this.canvasHeight);
        ctx.globalAlpha = 1;

        this.loadImage("shape_mask", { src: shape.mask }).then((data) => {
          ctx.globalCompositeOperation = "destination-in";
          ctx.drawImage(data.img, 0, 0, this.canvasWidth, this.canvasHeight);
          ctx.globalCompositeOperation = "source-over";
          return resolve();
        });
      });
    });
  }

  applyPrintPattern(printPattern, resolve, ctx, offsetX, offsetY) {
    let shape = store.getters.shape;

    if (!shape) {
      return super.applyPrintPattern(
        printPattern,
        resolve,
        ctx,
        offsetX,
        offsetY
      );
    }

    this.loadImage("print_mask", { src: shape.print_mask }).then((data) => {
      let tempCanvas = document.createElement("canvas");
      let tempCtx = tempCanvas.getContext("2d");
      tempCanvas.width = this.canvasWidth;
      tempCanvas.height = this.canvasHeight;
      this.drawPattern(tempCtx, printPattern, this.panelPath, offsetX, offsetY);
      tempCtx.globalCompositeOperation = "destination-in";
      tempCtx.drawImage(data.img, 0, 0, this.canvasWidth, this.canvasHeight);

      super.applyPrintPattern(tempCanvas, resolve, ctx, 0, 0);
    });
  }

  getPanelPath() {
    let path = new Path2D();

    path.moveTo(0, 0);
    path.lineTo(this.canvasWidth, 0);
    path.lineTo(this.canvasWidth, this.canvasHeight);
    path.lineTo(0, this.canvasHeight);
    return path;
  }
}
