<template>
  <div
    role="tablist"
    :aria-label="label"
    @keyup.left="handlePrevious"
    @keyup.right="handleNext"
    @keyup.down="handleSelect"
    @keyup.enter="handleSelect"
  >
    <accessible-tab
      v-for="tab in tabs"
      :key="tab.id"
      :class="[
        tabClass,
        tab.id == selected ? activeClass : null,
        tab.id != selected ? inactiveClass : null,
        tab.disabled ? disabledClass : null,
        !tab.disabled ? 'cursor-pointer' : 'cursor-default',
      ]"
      :id="tab.id"
      :selected="tab.id == selected"
      @click="$emit('select', tab.id)"
    >
      <slot name="tab" :tab="tab">
        {{ tab.label }}
      </slot>
    </accessible-tab>
  </div>
</template>

<script>
import AccessibleTab from "./AccessibleTab";

export default {
  components: {
    AccessibleTab,
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    tabs: {
      type: Array,
      required: true,
    },

    selected: {
      type: String,
      required: true,
    },

    tabClass: {
      type: String,
    },

    activeClass: {
      type: String,
    },

    inactiveClass: {
      type: String,
    },

    disabledClass: {
      type: String,
    },
  },

  computed: {
    selectedIndex() {
      return this.tabs.findIndex((tab) => {
        return tab.id == this.selected;
      });
    },
  },

  methods: {
    handlePrevious() {
      let previousIndex = (this.tabs
        .map((tab, i) => [i, tab])
        .filter(([i, tab]) => {
          return i < this.selectedIndex && !tab.disabled;
        })
        .pop() || [-1])[0];

      if (previousIndex !== -1) {
        this.$emit("select", this.tabs[previousIndex].id);
      }
    },

    handleNext() {
      let nextIndex = this.tabs.findIndex((tab, i) => {
        return i > this.selectedIndex && !tab.disabled;
      });

      if (nextIndex !== -1) {
        this.$emit("select", this.tabs[nextIndex].id);
      }
    },

    handleSelect() {
      const tab = this.tabs[this.selectedIndex];
      document.getElementById(tab.controls).focus();
    },
  },
};
</script>
