<template>
  <footer class="flex items-center py-3 px-5 bg-gray-700 text-gray-200 text-sm print:hidden">
    <div>
      &copy;{{ new Date().getFullYear() }}
      <a href="https://www.takeform.net" class="hover:underline">Takeform</a>
      <span class="mx-2 opacity-50">|</span>
      <a href="https://www.takeform.net/privacy-policy" class="hover:underline"
        >Privacy Policy</a
      >
    </div>
    <div class="opacity-80 ml-auto text-xs">
      Web App by
      <a href="https://www.corporatecomm.com" class="hover:underline"
        >Corporate Communications</a
      >
    </div>
  </footer>
</template>
