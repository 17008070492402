<template>
  <accessible-tab-panel class="w-full p-6 overflow-y-auto overflow-x-hidden" id="tab-content">
    <fieldset>
      <legend v-if="title" class="text-lg mb-6" v-html="title" />
      <slot />
    </fieldset>
  </accessible-tab-panel>
</template>

<script>
import AccessibleTabPanel from "./AccessibleTabPanel";

export default {
  components: {
    AccessibleTabPanel,
  },

  props: {
    title: {
      type: String,
    },
  },
};
</script>
