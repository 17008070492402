<template>
  <transition
    enter-active-class="transition transform duration-200 ease-in"
    enter-from-class="opacity-0 -translate-x-10"
  >
    <div
      v-if="next"
      class="flex items-center bg-brand-yellow text-white py-2 px-4 font-medium text-sm items-center cursor-pointer group z-10"
      role="button"
      tabindex="0"
      @click="handleNext"
      @keydown.enter="handleNext"
    >
      <div class="flex-1 text-right">Continue: {{ text }}</div>
      <div class="ml-4" aria-hidden="true">
        <chevron-right-icon
          class="relative h-5 left-0 group-hover:left-1 transition-all"
        />
      </div>
    </div>
    <div
      v-else-if="configured"
      class="flex items-center bg-brand-yellow text-white py-2 px-4 font-medium text-sm items-center cursor-pointer group z-10"
      role="button"
      tabindex="0"
      @click="$store.commit('openSendDialog')"
      @keydown.enter="$store.commit('openSendDialog')"
    >
      <div class="flex-1 text-right">Next step: contact Takeform</div>
      <div class="ml-4" aria-hidden="true">
        <chevron-right-icon
          class="relative h-5 left-0 group-hover:left-1 transition-all"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/20/solid";

export default {
  components: {
    ChevronRightIcon,
  },

  props: {
    tab: {
      type: String,
      required: true,
    },
  },

  computed: {
    next() {
      switch (this.tab) {
        case "material":
          if (this.$store.state.material) {
            return (this.$store.state.type == "shape") ? "shape" : "pattern";
          }
          break;
        case "shape":
          if (this.$store.state.shape) {
            return "pattern";
          }
          break;
        case "pattern":
          if (this.$store.state.printPattern) {
            return "print-color";
          } else if (
            this.$store.state.cutPattern &&
            this.$store.getters.adapterAvailable
          ) {
            return "adapter";
          }
          break;
        case "print-color":
          if (
            this.$store.state.printColor &&
            this.$store.getters.adapterAvailable
          ) {
            return "adapter";
          }
          break;
      }
      return null;
    },

    text() {
      switch (this.next) {
        case "shape":
          return "choose shape";
        case "pattern":
          return (this.$store.state.type == "shape") ? "choose print pattern" : "choose print/push-thru";
        case "print-color":
          return "choose print color";
        case "adapter":
          return "choose adapter/leg color";
        default:
          return null;
      }
    },

    configured() {
      return this.$store.getters.configured;
    }
  },

  methods: {
    handleNext() {
      this.$store.commit("setConfigTab", this.next);
    },
  },
};
</script>
