<template>
  <div class="flex flex-col relative mx-auto lg:h-screen">
    <design-header />
    <main class="flex flex-col flex-1 relative w-full min-h-0 bg-white">
      <transition
        enter-active-class="transition duration-300 ease-in"
        enter-from-class="opacity-0"
      >
        <intro-screen v-if="!type" class="flex-1" />
        <design-board v-else class="flex-1 min-h-0" />
      </transition>
    </main>
    <design-footer />
  </div>
</template>

<script>
import DesignBoard from "./components/DesignBoard";
import DesignFooter from "./components/DesignFooter";
import DesignHeader from "./components/DesignHeader";
import IntroScreen from "./components/IntroScreen";

export default {
  components: {
    DesignBoard,
    DesignFooter,
    DesignHeader,
    IntroScreen,
  },

  computed: {
    type() {
      return this.$store.state.type;
    },
  },

  created() {
    this.$store.dispatch("parseUrl");
  },
};
</script>
