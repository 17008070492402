<template>
	<config-note>
		*Color representations will vary depending on monitor/screen. Please
		<span class="underline cursor-pointer" @click="$store.commit('openSendDialog')">request material samples</span>
		and color swatches of your selections before placing an order.
    </config-note>
</template>

<script>
import ConfigNote from "./ConfigNote";

export default {
  components: {
    ConfigNote,
  },
}
</script>