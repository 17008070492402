import Panel from "./Panel.js";

export default class PanelSwatch extends Panel {
  constructor(scale = 1) {
    super();

    this.type = 'swatch';
    this.scale = scale;
    this.panelWidth = 23.5 * scale;
    this.panelHeight = 18 * scale;
    this.panelShadowBlur = 0.5 * scale;
    this.panelShadowColor = "rgba(0, 0, 0, .5)";

    this.canvasWidth = this.panelWidth + this.panelShadowBlur * 2;
    this.canvasHeight = this.panelHeight + this.panelShadowBlur * 2;

    this.panelPath = this.getPanelPath();
  }

  getPanelPath() {
    let path = new Path2D();
    let radius = this.scale * .25;
    return this.roundedRect(
      path,
      0,
      0,
      this.panelWidth,
      this.panelHeight,
      radius
    );
  }
}
