<template>
  <div
    class="flex md:block xl:flex items-center my-2 mx-4 print:block"
    role="button"
    tabindex="0"
    @keyup.enter="$emit('click')"
  >
    <template v-if="item">
      <img
        v-if="item.thumb"
        :src="item.thumb"
        class="rounded shadow mr-3 w-12 my-2"
        alt=""
      />
      <svg
        v-else-if="item.color"
        viewBox="0 0 20 20"
        :fill="item.color"
        class="rounded shadow mr-3 w-12 my-2 h-12"
        aria-hidden="true"
      >
        <rect width="20" height="20" />
      </svg>
    </template>
    <div
      v-else
      class="rounded mr-3 w-12 my-2 h-12 border-2 border-dashed border-gray-400"
      aria-hidden="true"
    />
    <div>
      <h2 class="font-medium text-sm">{{ label }}</h2>
      <div>{{ item ? item.name : "-" }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
    },
  },
};
</script>
