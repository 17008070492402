<template>
  <config-content title="Choose a print pattern">
    <config-grid>
      <config-item
        v-for="printPattern in printPatterns"
        :key="printPattern.id"
        :name="printPattern.name"
        :image="printPattern.thumb"
        :selected="printPattern.id == selectedPrintPattern"
        group="print-pattern"
        @select="handleSelect"
      />
    </config-grid>
  </config-content>
</template>

<script>
import printPatterns from "../data/print_patterns.json";
import ConfigContent from "./ConfigContent";
import ConfigGrid from "./ConfigGrid";
import ConfigItem from "./ConfigItem";

export default {
  components: {
    ConfigContent,
    ConfigGrid,
    ConfigItem,
  },

  data() {
    return {
      printPatterns: printPatterns
    };
  },

  computed: {
    selectedPrintPattern() {
      return this.$store.state.printPattern;
    },
  },

  methods: {
    handleSelect(id) {
      this.$store.commit("setPrintPattern", id);
    },
  },
};
</script>
