<template>
  <canvas
    :width="width"
    :height="height"
    ref="canvas"
  />
</template>

<script>
import PreviewMixin from "../mixins/PreviewMixin";
import PanelStacker from "../lib/PanelStacker";

export default {
  mixins: [PreviewMixin],

  data() {
    return {
      width: 2880,
      height: 1584,
      backgroundSrc: require("../assets/rooms/workstation/background.jpg"),
    };
  },

  methods: {
    draw() {
      this.$emit("update:loading", true);

      let x = 360;
      let y = 592;
      let spacing = this.panel.panelWidth + 12;

      this.drawBefore()
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x, y))
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x+spacing, y))
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x+spacing*2, y))
        .then(() => this.drawAfter())
        .then(() => this.$emit("update:loading", false));
    },
  },

  mounted() {
    this.panel = new PanelStacker(11.8);
    this.draw();
  },
};
</script>
