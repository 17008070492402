<template>
  <config-content title="Choose an adapter/leg color*">
    <div v-if="selectedType == 'wall'">
      Adapater is unavailable with Wall Panels
    </div>
    <div v-else-if="selectedType == 'shape'">
      Adapater is unavailable with Shape Panels
    </div>
    <config-grid v-else>
      <config-item
        v-for="adapter in adapters"
        :key="adapter.id"
        :name="adapter.name"
        :image="adapter.thumb"
        :selected="adapter.id == selectedAdapter"
        group="adapter"
        circle
        @select="handleSelect"
      />
    </config-grid>
    <color-note />
  </config-content>
</template>

<script>
import adapters from "../data/adapters.json";
import ConfigContent from "./ConfigContent";
import ConfigGrid from "./ConfigGrid";
import ConfigItem from "./ConfigItem";
import ColorNote from "./ColorNote";

export default {
  components: {
    ConfigContent,
    ConfigGrid,
    ConfigItem,
    ColorNote,
  },

  data() {
    return {
      adapters: adapters,
      disabled: !this.$store.getters.adapterAvailable,
    };
  },

  computed: {
    selectedAdapter() {
      return this.$store.state.adapter;
    },

    selectedType() {
      return this.$store.state.type;
    },
  },

  methods: {
    handleSelect(id) {
      this.$store.commit("setAdapter", id);
    },
  },
};
</script>
