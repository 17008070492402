<template>
  <div
    class="fixed inset-0 flex flex-col items-center justify-center p-4 bg-black bg-opacity-80 z-50"
    tabindex="0"
    @click="handleClose"
    @keyup.esc="handleClose"
  >
    <transition
      enter-active-class="transition transform duration-150 ease-in"
      enter-from-class="opacity-0 scale-90"
    >
      <div
        v-if="showContent"
        class="bg-white py-3 rounded-lg shadow max-h-full relative"
        :class="dialogClass"
        role="dialog"
        @click.stop
      >
        <div class="py-5 px-9 max-h-full overflow-auto">
          <slot />
        </div>
        <button type="button" class="absolute top-2 right-2" @click="$emit('close')">
          <x-mark-icon class="w-5 h-5 text-gray-400" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { XMarkIcon } from "@heroicons/vue/20/solid";

export default {
  components: {
    XMarkIcon
  },

  props: {
    dialogClass: {
      type: String,
    },
  },

  data() {
    return {
      showContent: false,
    };
  },

  methods: {
    handleClose() {
      this.showContent = false;
      this.$emit("close");
    },
  },

  mounted() {
    this.showContent = true;
    this.$el.focus();
  },
};
</script>
