<template>
	<label class="text-lg mb-1">
		<slot />
		<span v-if="required" class="ml-1 text-red-600" aria-hidden="true">*</span>
	</label>
</template>

<script>
export default {
	props: {
		required: {
			type: Boolean,
			default: false
		}
	}
}
</script>