<template>
  <config-content title="Choose a material*">
    <config-grid>
      <config-item
        v-for="material in materials"
        :key="material.id"
        :name="material.name"
        :image="material.thumb"
        :selected="material.id == selectedMaterial"
        group="material"
        circle
        @select="handleSelect"
      />
    </config-grid>
    <color-note />
  </config-content>
</template>

<script>
import materials from "../data/materials.json";
import ConfigContent from "./ConfigContent";
import ConfigGrid from "./ConfigGrid";
import ConfigItem from "./ConfigItem";
import ColorNote from "./ColorNote";

export default {
  components: {
    ConfigContent,
    ConfigGrid,
    ConfigItem,
    ColorNote,
  },

  data() {
    return {
      materials: materials,
    };
  },

  computed: {
    selectedMaterial() {
      return this.$store.state.material;
    },
  },

  methods: {
    handleSelect(id) {
      this.$store.commit("setMaterial", id);
    },
  },
};
</script>
