<template>
  <div
    :id="'tabpanel-' + id"
    role="tabpanel"
    tabindex="0"
    :aria-labelledby="'tab-' + id"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
