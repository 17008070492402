export default {
  data() {
    return {
      canvas: null,
      canvasTemp: null,
      ctx: null,
      ctxTemp: null,
      width: 1,
      height: 1,
      backgroundSrc: null,
      foregroundSrc: null,
      cache: {},
    };
  },

  computed: {
    lastUpdated() {
      return this.$store.state.lastUpdated;
    },
  },

  watch: {
    lastUpdated() {
      this.draw();
    },
  },

  methods: {
    draw() {},

    drawBefore() {
      this.ctxTemp.clearRect(0, 0, this.width, this.height);
      return this.drawImage(this.backgroundSrc, "background");
    },

    drawAfter() {
      return this.drawImage(this.foregroundSrc, "foreground")
        .then(() => {
          this.ctx.clearRect(0, 0, this.width, this.height);
          this.ctx.drawImage(this.canvasTemp, 0, 0);
        });
    },

    drawImage(src, cacheName) {
      return new Promise((resolve) => {
        if (!src) {
          return resolve();
        }

        if (this.cache[cacheName]) {
          this.ctxTemp.drawImage(
            this.cache[cacheName],
            0,
            0,
            this.width,
            this.height
          );
          return resolve();
        } else {
          let tmpImg = new Image();
          tmpImg.onload = () => {
            this.cache[cacheName] = tmpImg;
            this.ctxTemp.drawImage(tmpImg, 0, 0, this.width, this.height);
            return resolve();
          };
          tmpImg.src = src;
        }
      });
    },
  },

  mounted() {
    this.canvas = this.$refs.canvas;
    this.ctx = this.canvas.getContext("2d");

    this.canvasTemp = document.createElement('canvas');
    this.canvasTemp.width = this.width;
    this.canvasTemp.height = this.height;
    this.ctxTemp = this.canvasTemp.getContext("2d");
  },
};
