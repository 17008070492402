import Vuex from "vuex";

import types from "./data/types.json";
import materials from "./data/materials.json";
import shapes from "./data/shapes.json";
import printPatterns from "./data/print_patterns.json";
import cutPatterns from "./data/cut_patterns.json";
import printColors from "./data/print_colors.json";
import adapters from "./data/adapters.json";

export default Vuex.createStore({
  state: {
    type: null,
    material: null,
    shape: null,
    printPattern: null,
    cutPattern: null,
    printColor: null,
    adapter: null,
    lastUpdated: null,
    configTab: "material",
    sendDialog: false,
  },

  mutations: {
    setType(state, value) {
      if (value == 'shape') {
        state.cutPattern = null;
      }

      state.type = value;
      this.dispatch("setUrl");
    },

    setMaterial(state, value) {
      state.material = value;
      state.lastUpdated = new Date();
      this.dispatch("setUrl");
    },

    setShape(state, value) {
      state.shape = value;
      state.lastUpdated = new Date();
      this.dispatch("setUrl");
    },

    setPrintPattern(state, value) {
      state.printPattern = value;
      state.cutPattern = null;
      state.lastUpdated = new Date();
      this.dispatch("setUrl");
    },

    setCutPattern(state, value) {
      if (state.type == "shape") {
        return;
      }

      state.cutPattern = value;
      state.printPattern = null;
      state.lastUpdated = new Date();
      this.dispatch("setUrl");
    },

    setPrintColor(state, value) {
      state.printColor = value;
      state.lastUpdated = new Date();
      this.dispatch("setUrl");
    },

    setAdapter(state, value) {
      state.adapter = value;
      state.lastUpdated = new Date();
      this.dispatch("setUrl");
    },

    setConfigTab(state, value) {
      state.configTab = value;
    },

    openSendDialog(state) {
      state.sendDialog = true;
    },

    closeSendDialog(state) {
      state.sendDialog = false;
    },
  },

  getters: {
    type: (state) => {
      return types.find((item) => item.id == state.type);
    },

    material: (state) => {
      return materials.find((item) => item.id == state.material);
    },

    shape: (state) => {
      if (state.shape) {
        return shapes.find((item) => item.id == state.shape);
      } else {
        return shapes[0];
      }
    },

    printPattern: (state) => {
      return printPatterns.find((item) => item.id == state.printPattern);
    },

    cutPattern: (state) => {
      return cutPatterns.find((item) => item.id == state.cutPattern);
    },

    printColor: (state) => {
      return printColors.find((item) => item.id == state.printColor);
    },

    adapter: (state) => {
      return adapters.find((item) => item.id == state.adapter);
    },

    adapterAvailable: (state) => {
      return state.type != "wall" && state.type != "shape"
    },

    configured: (state, getters) => {
      return (
        state.type &&
        state.material &&
        (state.printPattern || state.cutPattern) &&
        (state.printColor || state.cutPattern) &&
        (state.adapter || !getters.adapterAvailable)
      );
    },

    url: (state) => {
      const baseUrl = window.location.href.split("?")[0];
      const url = new URL(baseUrl);

      if (state.type) {
        url.searchParams.append("type", state.type);
      }
      if (state.material) {
        url.searchParams.append("material", state.material);
      }
      if (state.shape) {
        url.searchParams.append("shape", state.shape);
      }
      if (state.printPattern) {
        url.searchParams.append("pattern", state.printPattern);

        if (state.printColor) {
          url.searchParams.append("color", state.printColor);
        }
      } else if (state.cutPattern) {
        url.searchParams.append("cut", state.cutPattern);
      }
      if (state.adapter) {
        url.searchParams.append("adapter", state.adapter);
      }

      return url.href;
    },
  },

  actions: {
    setUrl({ getters }) {
      window.history.replaceState(null, "", getters.url);
    },

    parseUrl({ commit }) {
      const params = new URLSearchParams(window.location.search);
      const type = params.get("type");
      const material = params.get("material");
      const shape = params.get("shape");
      const pattern = params.get("pattern");
      const cut = params.get("cut");
      const color = params.get("color");
      const adapter = params.get("adapter");

      if (type && types.find((item) => item.id == type)) {
        commit("setType", type);
      }
      if (material && materials.find((item) => item.id == material)) {
        commit("setMaterial", material);
      }
      if (shape && shapes.find((item) => item.id == shape)) {
        commit("setShape", shape);
      }
      if (pattern && printPatterns.find((item) => item.id == pattern)) {
        commit("setPrintPattern", pattern);
      }
      if (cut && cutPatterns.find((item) => item.id == cut)) {
        commit("setCutPattern", cut);
      }
      if (color && printColors.find((item) => item.id == color)) {
        commit("setPrintColor", color);
      }
      if (adapter && adapters.find((item) => item.id == adapter)) {
        commit("setAdapter", adapter);
      }
    },
  },
});
