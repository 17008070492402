import Panel from "./Panel.js";

export default class PanelFreestanding extends Panel {
  constructor(scale = 1) {
    super();

    this.type = 'freestanding';
    this.scale = scale;
    this.panelWidth = 30 * scale;
    this.panelHeight = 72 * scale;
    this.panelShadowBlur = 0.5 * scale;
    this.panelShadowColor = "rgba(0, 0, 0, .15)";
    this.notchWidth = 18 * scale;
    this.notchHeight = 1 * scale;
    this.notchGapX = .4 * scale;
    this.notchGapY = .5 * scale;
    this.adapterWidth = 0.6 * scale;
    this.adapterHeight = 60 * scale;
    this.adapterBaseOverhang = 0.5 * scale;
    this.adapterLockOffset = 3 * scale;
    this.adapterShadowBlur = .5 * scale;
    this.adapterShadowColor = "rgba(0, 0, 0, .15)";

    this.canvasWidth = this.panelWidth + this.panelShadowBlur * 2;
    this.canvasHeight = this.panelHeight + this.panelShadowBlur + this.adapterBaseOverhang + this.adapterShadowBlur;

    this.panelPath = this.getPanelPath();
    this.adapterPath = this.getAdapterPath();
  }

  getPanelPath() {
    let tl = { x: 0, y: 0 };
    let tr = { x: this.panelWidth, y: 0 };
    let bl = { x: 0, y: this.panelHeight };
    let br = { x: this.panelWidth, y: this.panelHeight };
    let radius = 2 * this.scale;

    let notch = {
      tl: {
        x: (this.panelWidth - this.notchWidth) / 2,
        y: this.panelHeight - this.notchHeight,
      },
      tr: {
        x: (this.panelWidth - this.notchWidth) / 2 + this.notchWidth,
        y: this.panelHeight - this.notchHeight,
      },
      bl: {
        x: (this.panelWidth - this.notchWidth) / 2,
        y: this.panelHeight,
      },
      br: {
        x: (this.panelWidth - this.notchWidth) / 2 + this.notchWidth,
        y: this.panelHeight,
      },
      radius: 0,
    };

    let path = new Path2D();
    path.moveTo(tl.x + radius, tl.y);
    path.arcTo(tr.x, tr.y, tr.x, tr.y + radius, radius);
    path.arcTo(br.x, br.y, br.x - radius, br.y, radius);
    path.arcTo(
      notch.br.x,
      notch.br.y,
      notch.br.x,
      notch.br.y - notch.radius,
      notch.radius
    );
    path.arcTo(
      notch.tr.x,
      notch.tr.y,
      notch.tr.x - notch.radius,
      notch.tr.y,
      notch.radius
    );
    path.arcTo(
      notch.tl.x,
      notch.tl.y,
      notch.tl.x,
      notch.tl.y + notch.radius,
      notch.radius
    );
    path.arcTo(
      notch.bl.x,
      notch.bl.y,
      notch.bl.x - notch.radius,
      notch.bl.y,
      notch.radius
    );
    path.arcTo(bl.x, bl.y, bl.x, bl.y - radius, radius);
    path.arcTo(tl.x, tl.y, tl.x + radius, tl.y, radius);

    return path;
  }

  getAdapterPath() {
    let path = new Path2D();
    let width = this.adapterWidth;
    let height = this.adapterHeight;
    let x = (this.panelWidth - this.adapterWidth) / 2;
    let y = this.panelHeight + this.adapterBaseOverhang - height;
    let radius = width * 0.25;

    this.roundedRect(path, x, y, width, height, radius);
    this.roundedRect(path, x - width / 2, y + this.adapterLockOffset, width * 2, width, radius);

    width = this.notchWidth - this.notchGapX * 2;
    height = this.notchHeight - this.notchGapY + this.adapterBaseOverhang;
    x = (this.panelWidth - width) / 2;
    y = this.panelHeight - this.notchHeight + this.notchGapY;
    radius = height * 0.25;

    this.roundedRect(path, x, y, width, height, radius);
    return path;
  }
}
