<template>
  <accessible-tab-list
    class="flex bg-gray-100 border-b border-gray-200 pt-3 px-2"
    tab-class="p-3 text-center text-sm relative top-px"
    active-class="bg-white shadow"
    disabled-class="opacity-50"
    label="Configuration steps"
    :tabs="tabs"
    :selected="selected"
    @select="(id) => $store.commit('setConfigTab', id)"
  >
    <template v-slot:tab="{ tab }">
      <div class="flex flex-col items-center">
        <img :src="tab.image" class="mb-2 h-10" alt="" />
        <span v-html="tab.label" />
      </div>
    </template>
  </accessible-tab-list>
</template>

<script>
import AccessibleTabList from "./AccessibleTabList";

export default {
  components: {
    AccessibleTabList,
  },

  computed: {
    tabs() {
      let tabs = [];
      let isShape = (this.$store.state.type == "shape");

      tabs.push({
        label: "Material",
        id: "material",
        image: require("../assets/icons/material.png"),
      });

      if (isShape) {
        tabs.push({
          label: "Shape",
          id: "shape",
          image: require("../assets/icons/shape.png"),
        });
      }

      tabs.push({
        label: isShape ? "Print Pattern" : "Print/<wbr>Push-Thru",
        id: "pattern",
        image: require("../assets/icons/pattern.png"),
      });

      if (this.$store.state.cutPattern == null) {
        tabs.push({
          label: "Print Color",
          id: "print-color",
          image: require("../assets/icons/color.png"),
        });
      }

      if (this.$store.getters.adapterAvailable) {
        tabs.push({
          label: "Adapter/<wbr>Leg",
          id: "adapter",
          image: require("../assets/icons/adapter.png"),
        });
      }

      return tabs;
    },

    selected() {
      return this.$store.state.configTab;
    },
  },
};
</script>
