<template>
  <config-content title="Choose a print color*">
    <div v-if="selectedCutPattern">
      Print color is unavailable for push-thru patterns
    </div>
    <config-grid v-else>
      <config-item
        v-for="printColor in printColors"
        :key="printColor.id"
        :name="printColor.name"
        :color="printColor.color"
        :selected="printColor.id == selectedPrintColor"
        group="print-color"
        circle
        @select="handleSelect"
      />
    </config-grid>
    <color-note />
  </config-content>
</template>

<script>
import printColors from "../data/print_colors.json";
import ConfigContent from ".//ConfigContent";
import ConfigGrid from ".//ConfigGrid";
import ConfigItem from "./ConfigItem";
import ColorNote from "./ColorNote";

export default {
  components: {
    ConfigContent,
    ConfigGrid,
    ConfigItem,
    ColorNote,
  },

  data() {
    return {
      printColors: printColors,
    };
  },

  computed: {
    selectedPrintColor() {
      return this.$store.state.printColor;
    },

    selectedCutPattern() {
      return this.$store.state.cutPattern;
    },
  },

  methods: {
    handleSelect(id) {
      this.$store.commit("setPrintColor", id);
    },
  },
};
</script>
