<template>
  <canvas
    :width="width"
    :height="height"
    ref="canvas"
  />
</template>

<script>
import PreviewMixin from "../mixins/PreviewMixin";
import PanelShape from "../lib/PanelShape";

export default {
  mixins: [PreviewMixin],

  data() {
    return {
      width: 2160,
      height: 1080,
      backgroundSrc: require("../assets/rooms/shape/background.jpg"),
      foregroundSrc: null,
    };
  },

  methods: {
    draw() {
      this.$emit("update:loading", true);

      this.drawBefore()
        .then(() => this.panel.draw(0, -60))
        .then((canvas) => this.ctxTemp.drawImage(canvas, 0, 0))
        .then(() => this.drawAfter())
        .then(() => this.$emit("update:loading", false));
    },
  },

  mounted() {
    this.panel = new PanelShape(8.13, this.width, this.height);
    this.draw();
  },
};
</script>
