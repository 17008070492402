<template>
  <div class="flex flex-col items-center justify-center bg-white min-h-full overflow-auto p-4">

    <div class="text-gray-700 text-center max-w-6xl mx-auto">
      <div class="text-xl md:text-2xl lg:text-4xl font-light text-gray-500 text-center mb-14">
        <div class="leading-snug">
          The <span class="font-normal"><span class="text-brand-green">Oomph</span> Pattern Visualizer</span>
          lets you explore thousands of material, pattern, and ink color combinations with just a few clicks
        </div>
      </div>

      <div class="text-xl lg:text-3xl font-thin mb-3">
        Start by selecting from one of four Oomph applications.
      </div>

      <div class="text-gray-500">
        (Note: you’ll be able to see your selections applied to the other applications at any time throughout the process.)
      </div>
    </div>
    
    <div class="sm:flex mt-6">
      <intro-type
        v-for="type in types"
        :key="type.id"
        :name="type.name"
        :image="type.intro"
        class="m-4"
        @click="selectType(type)"
        @keyup.enter="selectType(type)"
      />
    </div>
  </div>
</template>

<script>
import types from "../data/types.json";
import IntroType from "./IntroType";

export default {
  components: {
    IntroType,
  },

  data() {
    return {
      types: types,
      mounted: false,
    };
  },

  methods: {
    selectType(type) {
      this.$store.commit("setType", type.id);
    },
  },

  mounted() {
    this.mounted = true;
  },

  beforeUnmount() {
    this.mounted = false;
  },
};
</script>
