<template>
  <canvas
    :width="width"
    :height="height"
    ref="canvas"
  />
</template>

<script>
import PreviewMixin from "../mixins/PreviewMixin";
import PanelFreestanding from "../lib/PanelFreestanding";

export default {
  mixins: [PreviewMixin],

  data() {
    return {
      width: 2880,
      height: 1975,
      backgroundSrc: require("../assets/rooms/freestanding/background.jpg"),
      foregroundSrc: require("../assets/rooms/freestanding/foreground.png"),
    };
  },

  methods: {
    draw() {
      this.$emit("update:loading", true);

      let x = 706;
      let y = 464;
      let spacing = this.panel.panelWidth + 34;

      this.drawBefore()
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x, y))
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x+spacing, y))
        .then(() => this.panel.draw())
        .then((canvas) => this.ctxTemp.drawImage(canvas, x+spacing*2, y))
        .then(() => this.drawAfter())
        .then(() => this.$emit("update:loading", false));
    },
  },

  mounted() {
    this.panel = new PanelFreestanding(15.35);
    this.draw();
  },
};
</script>
