<template>
  <canvas
    :width="width"
    :height="height"
    ref="canvas"
  />
</template>

<script>
import PreviewMixin from "../mixins/PreviewMixin";
import PanelSwatch from "../lib/PanelSwatch";


export default {
  mixins: [PreviewMixin],

  data() {
    return {
      width: 0,
      height: 0,
      padding: 100,
    };
  },

  methods: {
    draw() {
      this.$emit("update:loading", true);

      this.drawBefore()
        .then(() => this.panel.draw())
        .then((canvas) => {
          this.ctxTemp.drawImage(canvas, this.padding, this.padding);
        })
        .then(() => this.drawAfter())
        .then(() => this.$emit("update:loading", false));
    },
  },

  created () {
    this.panel = new PanelSwatch(60);
    this.width = this.panel.canvasWidth + this.padding * 2;
    this.height = this.panel.canvasHeight + this.padding * 2;
  },

  mounted() {
    this.draw();
  },
};
</script>
