<template>
	<input
		class="w-full border p-2 rounded focus:ring outline-none"
		:required="required"
		:aria-required="required"
		@input="handleInput"
	/>
</template>

<script>
export default {
	props: {
		modelValue: {
			required: true
		},
		required: {
			type: Boolean
		}
	},

	methods: {
		handleInput (event) {
			this.$emit('update:modelValue', event.target.value)
		}
	}
}
</script>