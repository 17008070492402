<template>
  <div class="lg:flex">
    <design-config
      class="lg:w-1/4 border-b border-gray-200"
      style="min-width: 350px;"
    />
    <design-preview class="flex-1" />
    <dialog-send v-if="$store.state.sendDialog" @close="$store.commit('closeSendDialog')" />
  </div>
</template>

<script>
import DesignConfig from "./DesignConfig";
import DesignPreview from "./DesignPreview";
import DialogSend from "./DialogSend";

export default {
  components: {
    DesignConfig,
    DesignPreview,
    DialogSend,
  },

  data() {
    return {
      send: false,
    };
  },
};
</script>
