<template>
  <div
    :id="'tab-' + id"
    role="tab"
    :aria-controls="'tabpanel-' + id"
    :aria-selected="selected"
    :aria-disabled="disabled"
    :tabindex="selected ? 0 : -1"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  updated() {
    if (this.selected) {
      this.$el.focus();
    }
  },
};
</script>
