<template>
  <dialog-modal dialog-class="w-full max-w-screen-md">
    <template v-if="configured">
      <form v-if="!sent"  @submit.prevent="submit" autocomplete="off">
        <div class="md:flex">
          <div class="flex-1 mb-2">
            <form-label for="name" required>Name</form-label>
            <form-input type="text" id="name" v-model="name" required />
          </div>
          <div class="flex-1 mb-2 md:ml-4">
            <form-label for="email" required>Email</form-label>
            <form-input type="email" id="email" v-model="email" required />
          </div>
        </div>

        <div class="md:flex">
          <div class="flex-1 mb-2">
            <form-label for="city">City</form-label>
            <form-input type="text" id="city" v-model="city" />
          </div>
          <div class="flex-1 mb-2 md:ml-4">
            <form-label for="state" required>State</form-label>
            <form-input type="text" id="state" v-model="state" required />
          </div>
        </div>

        <div class="mb-2">
          <form-label for="company" required>Company</form-label>
          <form-input type="text" id="company" v-model="company" required />
        </div>

        <div class="mb-2">
          <form-label for="project">Project Information</form-label>
          <form-textarea id="project" v-model="project" />
        </div>

        <form-fieldset legend="Interested In" class="mb-2" required>
          <div class="flex flex-wrap">
            <form-checkbox id="product-workstations" value="Workstations" class="mr-3" v-model="products">
              <form-label for="product-workstations" class="cursor-pointer">Workstations</form-label>
            </form-checkbox>

            <form-checkbox id="product-freestanding" value="Freestanding" class="mr-3" v-model="products">
              <form-label for="product-freestanding" class="cursor-pointer">Freestanding</form-label>
            </form-checkbox>

            <form-checkbox id="product-wall" value="Wall Panels" class="mr-3" v-model="products">
              <form-label for="product-wall" class="cursor-pointer">Wall Panels</form-label>
            </form-checkbox>

            <form-checkbox id="product-shape" value="Shapes" v-model="products">
              <form-label for="product-shape" class="cursor-pointer">Shapes</form-label>
            </form-checkbox>
          </div>
        </form-fieldset>

        <form-fieldset legend="Request Sample" class="mb-2">
          <div>
            <form-checkbox id="sample" v-model="sample">
              <form-label for="sample" class="cursor-pointer">Provide swatch of specified material.</form-label>
            </form-checkbox>
          </div>
        </form-fieldset>

        <div v-if="error" class="p-3 mb-2 bg-red-50 border border-red-800 text-red-800 rounded whitespace-pre-line">{{ error }}</div>

        <div class="text-center mt-4">
          <x-button
            :class="{'opacity-50': loading}" 
            primary 
            large 
            submit
            :disabled="loading"
          >
            Request Follow-up
          </x-button>
        </div>
      </form>
      <transition
          enter-active-class="transition duration-300 ease-in"
          enter-from-class="opacity-0"
        >
        <div v-if="sent" class="flex items-center">
          <div class="flex-1 mr-4">
            <div class="text-xl">Thank You!</div>
            <div class="mt-2">We will contact you with more information.</div>
          </div>
          <x-button primary @click="$emit('close')">
            Close
          </x-button>
        </div>
      </transition>
    </template>
    <div v-else class="py-6 text-lg text-center">
      Please complete all configurations before contacting Takeform.
    </div>
  </dialog-modal>
</template>

<script>
import DialogModal from "./DialogModal";
import FormLabel from "./FormLabel";
import FormInput from "./FormInput";
import FormTextarea from "./FormTextarea";
import FormFieldset from "./FormFieldset";
import FormCheckbox from "./FormCheckbox";
import XButton from "./Button";

export default {
  components: {
    DialogModal,
    FormLabel,
    FormInput,
    FormTextarea,
    FormFieldset,
    FormCheckbox,
    XButton,
  },

  data() {
    return {
      name: null,
      company: null,
      city: null,
      state: null,
      email: null,
      project: null,
      products: [],
      sample: 0,
      sent: false,
      error: null,
      loading: false
    };
  },

  computed: {
    material() {
      return this.$store.getters.material;
    },

    printPattern() {
      return this.$store.getters.printPattern;
    },

    cutPattern() {
      return this.$store.getters.cutPattern;
    },

    printColor() {
      return this.$store.getters.printColor;
    },

    adapter() {
      return this.$store.getters.adapter;
    },

    url() {
      return this.$store.getters.url;
    },

    configured() {
      return this.$store.getters.configured;
    },
  },

  methods: {
    submit() {
      const data = {
        name: this.name,
        company: this.company,
        city: this.city,
        state: this.state,
        email: this.email,
        project: this.project,
        sample: this.sample,
        products: this.products,
        material: this.material ? this.material.name : null,
        print_pattern: this.printPattern ? this.printPattern.name : null,
        print_color: this.printColor ? this.printColor.name : null,
        cut_pattern: this.cutPattern ? this.cutPattern.name : null,
        adapter: this.adapter ? this.adapter.name : null,
        url: this.url,
      }

      this.loading = true;

      fetch('/send.php', {
          method: 'POST',
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
          this.loading = false;
          if (data.success) {
            this.sent = true;
          } else if (data.error) {
            this.error = data.error;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = 'An error occurred';
        })
    },
  },

  mounted () {
    switch (this.$store.state.type) {
      case 'workstation':
        this.products = ['Workstations'];
        break;
      case 'freestanding':
        this.products = ['Freestanding'];
        break;
      case 'wall':
        this.products = ['Wall Panels'];
        break;
      case 'shape':
        this.products = ['Shapes'];
        break;
    }
  }
};
</script>
