<template>
  <div class="max-w-sm mb-6 cursor-pointer group" role="button" tabindex="0">
    <img :src="image" class="border-2 border-gray-400 rounded-lg group-hover:border-brand-yellow" alt="">
    <div class="text-xl text-gray-700 text-center mt-1">{{ name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    image: {
      type: String,
      required: true,
    }
  },
};
</script>
