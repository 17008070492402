<template>
  <section
    class="flex flex-col border-r-2 border-gray-200 overflow-hidden md:border-b-0 print:hidden"
    aria-label="Design Configuration"
  >
    <config-tabs class="order-1" />
    <div class="flex-1 flex flex-cols min-h-0 z-10 bg-white order-3">
      <transition
        enter-active-class="transition transform duration-200 ease-in"
        enter-from-class="opacity-0 translate-y-10"
      >
        <config-materials v-if="tab == 'material'" id="material" />
        <config-shapes v-else-if="tab == 'shape'" id="shape" />
        <config-patterns v-else-if="tab == 'pattern' && type != 'shape'" id="pattern" />
        <config-print-patterns v-else-if="tab == 'pattern' && type == 'shape'" id="pattern" />
        <config-print-colors
          v-else-if="tab == 'print-color'"
          id="print-color"
        />
        <config-adapters v-else-if="tab == 'adapter'" id="adapter" />
      </transition>
    </div>
    <config-next class="order-2" :tab="tab" />
  </section>
</template>

<script>
import ConfigTabs from "./ConfigTabs";
import ConfigMaterials from "./ConfigMaterials";
import ConfigShapes from "./ConfigShapes";
import ConfigPatterns from "./ConfigPatterns";
import ConfigPrintPatterns from "./ConfigPrintPatterns";
import ConfigPrintColors from "./ConfigPrintColors";
import ConfigAdapters from "./ConfigAdapters";
import ConfigNext from "./ConfigNext";

export default {
  components: {
    ConfigTabs,
    ConfigMaterials,
    ConfigShapes,
    ConfigPatterns,
    ConfigPrintPatterns,
    ConfigPrintColors,
    ConfigAdapters,
    ConfigNext,
  },

  computed: {
    tab() {
      return this.$store.state.configTab;
    },

    type() {
      return this.$store.state.type;
    }
  },

  methods: {
    handleSelect(i) {
      this.tab = i;
    },
  },
};
</script>
