<template>
	<div>
		<input :id="id" type="checkbox" :value="value" class="mr-1.5" ref="input" @input="handleInput" /> 
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		modelValue: {
			required: true
		},
		id: {
			type: String,
			required: true
		},
		value: {
			default: 1
		}
	},

	methods: {
		handleInput (event) {
			if (!Array.isArray(this.modelValue)) {
				return this.$emit('update:modelValue', this.value);
			}

			let values = this.modelValue.slice();
			let index = values.indexOf(this.value);

			if (event.target.checked) {
				if (index === -1) {
					values.push(this.value);
				}
			}
			else if (index !== -1) {
				values.splice(values.indexOf(this.value), 1);
			}

			this.$emit('update:modelValue', values);
			
		}
	},

	mounted () {
		if (Array.isArray(this.modelValue)) {
			this.$refs.input.checked = this.modelValue.indexOf(this.value) !== -1;
		} else {
			this.$refs.input.checked = this.modelValue == this.value;
		}
	}
}
</script>