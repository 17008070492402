<template>
  <config-content title='Choose a <a href="#print" class="underline">print<a> OR <a href="#push-thru" class="underline">push-thru<a> pattern'>
    <div id="print" class="mb-8">
      <div class="font-bold mb-2">Printed Patterns</div>
      <accessible-tab-panel id="tab-print-patterns">
        <config-grid>
          <config-item
            v-for="printPattern in printPatterns"
            :key="printPattern.id"
            :name="printPattern.name"
            :image="printPattern.thumb"
            :selected="printPattern.id == selectedPrintPattern"
            group="print-pattern"
            @select="handleSelectPrintPattern"
          />
        </config-grid>
      </accessible-tab-panel>
    </div>

    <div id="push-thru">
      <div class="font-bold mb-2">Push-Thru Patterns</div>
      <accessible-tab-panel id="tab-cut-patterns">
        <config-grid>
          <config-item
            v-for="cutPattern in cutPatterns"
            :key="cutPattern.id"
            :name="cutPattern.name"
            :image="cutPattern.thumb"
            :selected="cutPattern.id == selectedCutPattern"
            :asterisk="cutPattern.asterisk"
            group="cut-pattern"
            @select="handleSelectCutPattern"
          />
        </config-grid>
      </accessible-tab-panel>
    </div>

    <config-note>
      *Pattern requires standoff or cleat mounting to allow for bidirectional
      push-thru. Flush mount is not available.
    </config-note>
  </config-content>
</template>

<script>
import printPatterns from "../data/print_patterns.json";
import cutPatterns from "../data/cut_patterns.json";
import AccessibleTabPanel from "./AccessibleTabPanel";
import ConfigContent from "./ConfigContent";
import ConfigGrid from "./ConfigGrid";
import ConfigItem from "./ConfigItem";
import ConfigNote from "./ConfigNote";

export default {
  components: {
    AccessibleTabPanel,
    ConfigGrid,
    ConfigItem,
    ConfigContent,
    ConfigNote,
  },

  data() {
    return {
      printPatterns: printPatterns,
      cutPatterns: cutPatterns,
    };
  },

  computed: {
    selectedPrintPattern() {
      return this.$store.state.printPattern;
    },

    selectedCutPattern() {
      return this.$store.state.cutPattern;
    },
  },

  methods: {
    handleSelectPrintPattern(id) {
      this.$store.commit("setPrintPattern", id);
    },

    handleSelectCutPattern(id) {
      this.$store.commit("setCutPattern", id);
    },
  },
};
</script>
