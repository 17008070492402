<template>
	<fieldset>
		<legend v-if="legend" class="block text-lg mb-1">
			{{ legend }}
			<span v-if="required" class="ml-1 text-red-600" aria-hidden="true">*</span>
		</legend>
		<slot />
	</fieldset>
</template>

<script>
export default {
	props: {
		legend: {
			type: String,
		},
		required: {
			type: Boolean,
			default: false
		}
	}
}
</script>