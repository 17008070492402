import Panel from "./Panel.js";

export default class PanelStacker extends Panel {
  constructor(scale = 1) {
    super();

    this.type = 'stacker';
    this.scale = scale;
    this.panelWidth = 60 * scale;
    this.panelHeight = 18 * scale;
    this.panelShadowBlur = 0.5 * scale;
    this.panelShadowColor = "rgba(0, 0, 0, .15)";
    this.adapterWidth = 0.6 * scale;
    this.adapterSpacing = 20.5 * scale;
    this.adapterOverlap = 2.5 * scale;
    this.adapterOverhang = 3 * scale;
    this.adapterShadowBlur = .5 * scale;
    this.adapterShadowColor = "rgba(0, 0, 0, .15)";

    this.canvasWidth = this.panelWidth + this.panelShadowBlur * 2;
    this.canvasHeight =
      this.panelHeight +
      this.panelShadowBlur +
      this.adapterOverhang +
      this.adapterShadowBlur;

    this.panelPath = this.getPanelPath();
    this.adapterPath = this.getAdapterPath();
  }

  getPanelPath() {
    let path = new Path2D();
    let radius = this.scale;
    return this.roundedRect(
      path,
      0,
      0,
      this.panelWidth,
      this.panelHeight,
      radius
    );
  }

  getAdapterPath() {
    let path = new Path2D();
    let width = this.adapterWidth;
    let height = this.adapterOverlap + this.adapterOverhang;
    let x = this.panelWidth / 2 - this.adapterWidth / 2;
    let y = this.panelHeight - this.adapterOverlap;
    let radius = width * 0.33;

    this.roundedRect(path, x, y, width, height, radius);
    x -= this.adapterSpacing;
    this.roundedRect(path, x, y, width, height, radius);
    x += this.adapterSpacing * 2;
    this.roundedRect(path, x, y, width, height, radius);
    return path;
  }
}
