<template>
  <section class="flex flex-col" aria-label="Design Preview">
    <preview-header v-model:mode="mode" v-model:type="type" />
    <preview-content :mode="mode" :type="type" />
    <preview-footer />
  </section>
</template>

<script>
import PreviewHeader from "./PreviewHeader";
import PreviewContent from "./PreviewContent";
import PreviewFooter from "./PreviewFooter";

export default {
  components: {
    PreviewHeader,
    PreviewContent,
    PreviewFooter,
  },

  data() {
    return {
      mode: "room",
    };
  },

  computed: {
    type: {
      get() {
        return this.$store.state.type;
      },
      set(value) {
        this.$store.commit("setType", value);
      },
    },
  },
};
</script>
