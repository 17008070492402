import store from "../store";

export default class Panel {
  constructor() {
    this.canvas = document.createElement("canvas");
    this.ctx = this.canvas.getContext("2d");

    this.type = null;
    this.scale = 1;
    this.panelNumber = 1;
    this.canvasWidth = 1;
    this.canvasHeight = 1;
    this.panelShadowBlur = 0;
    this.panelShadowColor = "rgba(0, 0, 0, .5)";
    this.adapterShadowBlur = 0;
    this.adapterShadowColor = "rgba(0, 0, 0, .5)";
    this.imageCache = {};
    this.panelPath = null;
    this.adapterPath = null;
  }

  draw(patternOffsetX = 0, patternOffsetY = 0) {
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;
    
    this.ctx.translate(this.panelShadowBlur, this.panelShadowBlur);

    return new Promise((resolve) => {
      this.beforeDraw(this.ctx)
        .then(() => this.drawPanel(this.ctx))
        .then(() => this.drawMaterial(this.ctx))
        .then(() => this.drawPrintPattern(this.ctx, patternOffsetX, patternOffsetY))
        .then(() => this.drawCutPattern(this.ctx))
        .then(() => this.drawAdapter(this.ctx))
        .then(() => this.afterDraw(this.ctx))
        .then(() => resolve(this.canvas));
    });
  }

  beforeDraw() {
    return new Promise((resolve) => {
      return resolve();
    });
  }

  afterDraw() {
    return new Promise((resolve) => {
      return resolve();
    });
  }

  drawPanel(ctx) {
    return new Promise((resolve) => {
      ctx.shadowBlur = this.panelShadowBlur;
      ctx.shadowColor = this.panelShadowColor;
      ctx.fillStyle = "#444";
      ctx.fill(this.panelPath);
      ctx.shadowBlur = null;
      ctx.shadowColor = null;
      return resolve();
    });
  }

  drawMaterial(ctx) {
    return new Promise((resolve) => {
      const material = store.getters.material;

      if (!material) {
        return resolve();
      }

      let imageData = material[this.type] || material.default;
      this.loadImage("material", imageData).then((data) => {
        let canvas = this.buildPattern(data);
        this.drawPattern(ctx, canvas, this.panelPath);
        return resolve();
      });
    });
  }

  drawPrintPattern(ctx, offsetX, offsetY) {
    return new Promise((resolve) => {
      const printPattern = store.getters.printPattern;
      const printColor = store.getters.printColor;
      
      if (!printPattern) {
        return resolve();
      }

      let imageData = printPattern[this.type] || printPattern.default;
      this.loadImage("printPattern", imageData).then((data) => {
        let printPattern = this.buildPattern(data);
        let color = printColor ? printColor.color : "#444";
        this.colorize(printPattern, color);
        return this.applyPrintPattern(printPattern, resolve, ctx, offsetX, offsetY);
      });
    });
  }


  applyPrintPattern(printPattern, resolve, ctx, offsetX, offsetY) {
      ctx.globalAlpha = .65;
      this.drawPattern(ctx, printPattern, this.panelPath, offsetX, offsetY);
      ctx.globalAlpha = 1;
      resolve();
  }

  drawCutPattern(ctx) {
    return new Promise((resolve) => {
      const cutPattern = store.getters.cutPattern;

      if (!cutPattern) {
        return resolve();
      }

      let imageData = cutPattern[this.type] || cutPattern.wall;
      this.loadImage("cutPattern", imageData).then((data) => {
        let canvas = this.buildPattern(data);
        this.drawPattern(ctx, canvas, this.panelPath);
        return resolve();
      });
    });
  }

  drawAdapter(ctx) {
    return new Promise((resolve) => {
      const adapter = store.getters.adapter;

      if (!this.adapterPath) {
        return resolve();
      }

      ctx.fillStyle = '#444';
      ctx.shadowBlur = this.adapterShadowBlur;
      ctx.shadowColor = this.adapterShadowColor;
      ctx.fill(this.adapterPath);
      ctx.shadowBlur = null;
      ctx.shadowColor = null;

      if (!adapter) {
        return resolve();
      }

      let imageData = adapter[this.type] || adapter.default;
      this.loadImage("adapter", imageData).then((data) => {
        let canvas = this.buildPattern(data);
        this.drawPattern(ctx, canvas, this.adapterPath);
        return resolve();
      });
    });
  }

  loadImage(cacheKey, imageData) {
    return new Promise((resolve, reject) => {
      if (!imageData) {
        return reject();
      }

      if (
        !this.imageCache[cacheKey] ||
        this.imageCache[cacheKey].src != imageData.src
      ) {
        this.imageCache[cacheKey] = Object.assign({}, imageData);
      }

      if (this.imageCache[cacheKey].img) {
        return resolve(this.imageCache[cacheKey]);
      }

      let img = new Image();
      img.onload = () => {
        this.imageCache[cacheKey].img = img;
        return resolve(this.imageCache[cacheKey]);
      };
      img.src = imageData.src;
    });
  }

  buildPattern(data) {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let scale = this.scale / data.scale;
    canvas.width = data.img.width * scale;
    canvas.height = data.img.height * scale;

    ctx.drawImage(data.img, 0, 0, canvas.width, canvas.height);
    return canvas;
  }

  drawPattern(ctx, canvas, path, offsetX = 0, offsetY = 0) {
    ctx.fillStyle = ctx.createPattern(canvas, "repeat");

    if (canvas.width > this.panelWidth) {
      offsetX += (canvas.width - this.panelWidth) / 2;
    }

    if (canvas.height > this.panelHeight) {
      offsetY += (canvas.height - this.panelHeight) / 2;
    }
    
    ctx.save();    
    ctx.clip(path);
    ctx.translate(-offsetX, -offsetY);
    ctx.fillRect(0, 0, this.canvasWidth + offsetX, this.canvasHeight + offsetY);
    ctx.restore();
  }

  colorize(canvas, color) {
    let rgb = this.hexToRgb(color);
    if (!rgb) {
      return;
    }
    
    let ctx = canvas.getContext('2d');
    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    for (var i = 0; i < imageData.data.length; i += 4) {
      let r = imageData.data[i];
      let g = imageData.data[i+1];
      let b = imageData.data[i+2];
      let alpha = imageData.data[i+3];

      if (alpha == 0) {
        continue;
      }

      // Scale color based on pattern color
      r = Math.floor(255 * (r/255) + rgb[0] * (1 - (r/255)));
      g = Math.floor(255 * (g/255) + rgb[1] * (1 - (g/255)));
      b = Math.floor(255 * (b/255) + rgb[2] * (1 - (b/255)));

      imageData.data[i] = r;
      imageData.data[i+1] = g;
      imageData.data[i+2] = b;
    }

    ctx.putImageData(imageData, 0, 0);
  }

  texture(canvas) {
    let ctx = canvas.getContext('2d');
    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let canvasAvg = this.canvasAvg(canvas);

    for (let i = 0; i < imageData.data.length; i += 4) {
      let r = imageData.data[i];
      let g = imageData.data[i+1];
      let b = imageData.data[i+2];

      let avg = Math.floor((r + g + b) / 3); // calculate pixel average
      let color = 255 - Math.max(canvasAvg - avg, 0); // lighten pixel using canvas average
      color = ((color - (255 - canvasAvg)) / (255 - (255 - canvasAvg))) * (255 - 50) + 50; // boost contrast
      

      imageData.data[i] = color;
      imageData.data[i+1] = color;
      imageData.data[i+2] = color;
    }

    ctx.putImageData(imageData, 0, 0);
    return canvas;
  }

  canvasAvg(canvas) { 
    let ctx = canvas.getContext('2d');
    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    let avgTotal = 0;
    let count = 0;

    for (var i = 0; i < imageData.data.length; i += 4) {
      if (imageData.data[i+3] == 0) {
        continue;
      }

      let r = imageData.data[i];
      let g = imageData.data[i+1];
      let b = imageData.data[i+2];

      avgTotal += Math.floor((r + g + b) / 3);
      count++;
    }

    return Math.floor(avgTotal / count);
  }

  roundedRect(path, x, y, width, height, radius) {
    let tl = { x: x, y: y };
    let tr = { x: x + width, y: y };
    let bl = { x: x, y: y + height };
    let br = { x: x + width, y: y + height };

    path.moveTo(tl.x + radius, tl.y);
    path.arcTo(tr.x, tr.y, tr.x, tr.y + radius, radius);
    path.arcTo(br.x, br.y, br.x - radius, br.y, radius);
    path.arcTo(bl.x, bl.y, bl.x, bl.y - radius, radius);
    path.arcTo(tl.x, tl.y, tl.x + radius, tl.y, radius);
    return path;
  }

  hexToRgb(hex) {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
            ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))
  }
}
