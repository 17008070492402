<template>
  <div class="relative">
    <transition
      enter-active-class="transition-all duration-300"
      leave-active-class="transition-all duration-300"
      enter-from-class="opacity-0 scale-75"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        v-if="configured"
        class="absolute left-1/2 bottom-full mb-3 rounded transform -translate-x-1/2 py-1 px-3 shadow-lg bg-brand-yellow text-center text-white cursor-pointer print:hidden"
        @click="$store.commit('openSendDialog')"
        tabindex="0"
      >
        <div class="flex items-center justify-center py-1 px-2 group">
          <div class="text-lg">Next step: contact Takeform</div>
          <chevron-right-icon class="w-5 h-5 ml-3 mt-0.5 bg-white text-brand-yellow rounded-full relative left-0 transition-all group-hover:left-1" />
        </div>
      </div>
    </transition>

    <a class="absolute right-3 bottom-full mb-3 bg-white shadow py-1 px-3 rounded text-sm print:hidden" href="https://www.takeform.net/galleries/oomph" target="_blank">
        <span class="border-b border-gray-300">Visit the Gallery</span>
    </a>

    <preview-summary class="flex-1" />
  </div>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import PreviewSummary from "./PreviewSummary";

export default {
  components: {
    ChevronRightIcon,
    PreviewSummary,
  },

  computed: {
    configured() {
      return this.$store.getters.configured;
    },
  }
};
</script>
