<template>
  <button
    class="rounded"
    :class="{
      'bg-brand-yellow text-white': primary,
      'bg-brand-green text-white': secondary,
      'bg-gray-700 text-white': dark,
      'bg-white text-gray-700': light,
      'py-1 px-2 text-sm': small,
      'py-2 px-4 text-lg': large,
      'py-1.5 px-3': !small && !large,
    }"
    :type="submit ? 'submit' : 'button'"
    v-bind="$attrs"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    primary: {
      type: Boolean,
    },
    secondary: {
      type: Boolean,
    },
    dark: {
      type: Boolean,
    },
    light: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    submit: {
      type: Boolean,
    },
  },
};
</script>
