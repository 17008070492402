<template>
  <div class="flex-1 relative bg-gray-200 relative print:bg-transparent">
    <preview-swatch
      v-if="mode == 'swatch'"
      v-model:loading="loading"
      class="lg:absolute inset-0 w-full h-full object-contain"
    />
    <preview-workstation
      v-else-if="type == 'workstation'"
      v-model:loading="loading"
      class="lg:absolute inset-0 w-full h-full object-cover"
    />
    <preview-freestanding
      v-else-if="type == 'freestanding'"
      v-model:loading="loading"
      class="lg:absolute inset-0 w-full h-full object-cover"
    />
    <preview-wall
      v-else-if="type == 'wall'"
      v-model:loading="loading"
      class="lg:absolute inset-0 w-full h-full object-cover"
    />
    <preview-shape
      v-else-if="type == 'shape'"
      v-model:loading="loading"
      class="lg:absolute inset-0 w-full h-full object-cover"
    />
    <preview-loader v-if="loading" />
  </div>
</template>

<script>
import PreviewSwatch from "./PreviewSwatch";
import PreviewWorkstation from "./PreviewWorkstation";
import PreviewFreestanding from "./PreviewFreestanding";
import PreviewWall from "./PreviewWall";
import PreviewShape from "./PreviewShape";
import PreviewLoader from "./PreviewLoader";

export default {
  components: {
    PreviewSwatch,
    PreviewWorkstation,
    PreviewFreestanding,
    PreviewWall,
    PreviewShape,
    PreviewLoader,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    handleLoading() {
      this.loading = true;
    },
  }
};
</script>
