<template>
  <div class="justify-around bg-white border-gray-400 text-gray-800 md:flex print:flex">
    <preview-summary-item label="Use Type" :item="type" @click="clearType" />

    <preview-summary-item
      label="Material"
      :item="material"
      @click="openTab('material')"
    />

    <preview-summary-item
      v-if="type.id == 'shape'"
      label="Shape"
      :item="shape"
      @click="openTab('shape')"
    />

    <preview-summary-item
      v-if="cutPattern"
      label="Push-Thru Pattern"
      :item="cutPattern"
      @click="openTab('pattern')"
    />
    <preview-summary-item
      v-else
      label="Print Pattern"
      :item="printPattern"
      @click="openTab('pattern')"
    />

    <preview-summary-item
      v-if="!cutPattern"
      label="Print Color"
      :item="printColor"
      @click="openTab('print-color')"
    />

    <preview-summary-item
      v-if="type.id != 'wall' && type.id != 'shape'"
      label="Adapter/Leg"
      :item="adapter"
      @click="openTab('adapter')"
    />
  </div>
</template>

<script>
import PreviewSummaryItem from "./PreviewSummaryItem";

export default {
  components: {
    PreviewSummaryItem,
  },

  computed: {
    type() {
      return this.$store.getters.type;
    },

    material() {
      return this.$store.getters.material;
    },

    shape() {
      return this.$store.getters.shape;
    },

    printPattern() {
      return this.$store.getters.printPattern;
    },

    cutPattern() {
      return this.$store.getters.cutPattern;
    },

    printColor() {
      return this.$store.getters.printColor;
    },

    adapter() {
      return this.$store.getters.adapter;
    },
  },

  methods: {
    clearType() {
      this.$store.commit("setType", null);
    },

    openTab(id) {
      this.$store.commit("setConfigTab", id);
    },
  },
};
</script>
