<template>
  <div
    class="flex items-center bg-gray-700 text-gray-300 px-6 border-r border-gray-800 print:hidden"
  >
    <div class="hidden xl:block mr-8">Design Mode:</div>
    <accessible-tab-list
      class="flex mr-auto"
      tab-class="px-2 py-4 text-center border-b-4"
      active-class="border-gray-300 text-white"
      inactive-class="border-transparent"
      label="Design modes"
      :tabs="tabsMode"
      :selected="mode"
      @select="(id) => $emit('update:mode', id)"
    >
      <template v-slot:tab="{ tab }">
        <div class="flex items-center">
          <img v-if="tab.image" :src="tab.image" class="mr-2 h-4" alt="" />
          <component v-else-if="tab.icon" class="w-5 h-5 mr-2" :is="tab.icon" />
          {{ tab.label }}
        </div>
      </template>
    </accessible-tab-list>

    <transition
      enter-active-class="transition duration-100 ease-in"
      leave-active-class="transition duration-100 ease-in"
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
    >
      <accessible-tab-list
        v-if="mode == 'room'"
        class="flex ml-auto self-stretch"
        tab-class="flex items-center px-3 text-white shadow-inner"
        active-class="bg-brand-green"
        label="Room views"
        :tabs="tabsType"
        :selected="type"
        @select="(id) => $emit('update:type', id)"
      />
    </transition>

    <button type="button" title="Print" class="ml-6 cursor-pointer" @click="print"><printer-icon class="w-5 h-5" /></button>
  </div>
</template>

<script>
import { MagnifyingGlassPlusIcon, PrinterIcon } from "@heroicons/vue/20/solid";
import AccessibleTabList from "./AccessibleTabList";

export default {
  components: {
    MagnifyingGlassPlusIcon,
    PrinterIcon,
    AccessibleTabList,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tabsMode: [
        {
          label: "Swatch",
          id: "swatch",
          icon: 'MagnifyingGlassPlusIcon',
        },
        {
          label: "Room View",
          id: "room",
          image: require("../assets/icons/image.png"),
        },
      ],

      tabsType: [
        {
          label: "Workstation",
          id: "workstation",
        },
        {
          label: "Freestanding",
          id: "freestanding",
        },
        {
          label: "Wall Panel",
          id: "wall",
        },
        {
          label: "Shapes",
          id: "shape",
        },
      ],
    };
  },

  methods: {
    print() {
      window.print();
    }
  }
};
</script>
