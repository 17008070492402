import Panel from "./Panel.js";

export default class PanelWall extends Panel {
  constructor(scale = 1) {
    super();

    this.type = 'wall';
    this.scale = scale;
    this.panelWidth = 47 * scale;
    this.panelHeight = 94 * scale;
    this.panelShadowBlur = .33 * scale;
    this.panelShadowColor = "rgba(0, 0, 0, .15)";

    this.canvasWidth = this.panelWidth + this.panelShadowBlur * 2;
    this.canvasHeight = this.panelHeight + this.panelShadowBlur * 2;

    this.panelPath = this.getPanelPath();
  }

  getPanelPath() {
    let path = new Path2D();
    let radius = .25 * this.scale;
    return this.roundedRect(
      path,
      0,
      0,
      this.panelWidth,
      this.panelHeight,
      radius
    );
  }
}
