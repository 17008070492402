import materials from "./data/materials.json";
import printPatterns from "./data/print_patterns.json";
import cutPatterns from "./data/cut_patterns.json";
import adapters from "./data/adapters.json";
import types from "./data/types.json";

const preload = (src) => {
  let img = new Image();
  img.src = src;
};

materials.forEach((material) => preload(material.thumb));
printPatterns.forEach((printPattern) => preload(printPattern.thumb));
cutPatterns.forEach((cutPattern) => preload(cutPattern.thumb));
adapters.forEach((adapter) => preload(adapter.thumb));
types.forEach((type) => preload(type.intro));
