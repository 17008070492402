<template>
  <div
    class="group relative cursor-pointer focus-within:ring-2"
    @click="handleClick"
    @keyup.enter.stop="handleClick"
  >
    <div
      class="aspect-w-1 aspect-h-1 shadow bg-cover bg-center bg-gray-50"
      :class="{
        'rounded-full': circle,
        'rounded-lg': !circle,
      }"
      :style="style"
      aria-hidden="true"
    >
      <div>
        <check-icon
          v-if="selected"
          class="absolute -bottom-1 -right-1 w-6 h-6 p-1 bg-brand-yellow text-white rounded-full shadow"
        />
      </div>
    </div>
    <label
      :for="inputId"
      class="block text-center font-medium text-xs mt-1 text-gray-500 group-hover:text-brand-yellow"
    >
      {{ name }}<template v-if="asterisk">*</template>
    </label>
    <input
      type="radio"
      class="sr-only"
      :id="inputId"
      :name="group"
      :checked="selected"
    />
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/20/solid";

export default {
  components: {
    CheckIcon,
  },

  props: {
    group: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
    },
    color: {
      type: String,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    style() {
      if (this.image) {
        return "background-image: url('" + this.image + "');";
      } else if (this.color) {
        return "background-color: " + this.color + ";";
      } else {
        return "display: none;";
      }
    },

    inputId() {
      return this.group + "-" + this.$.vnode.key;
    },
  },

  methods: {
    handleClick() {
      this.$emit("select", this.$.vnode.key);
    },
  },
};
</script>
